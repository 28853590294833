<template>
    <div class="avatar">
        <avataaars v-bind="user.avataaars" v-if="user.avataaars"/>
        <label class="ui initial label" :class="initialClass" v-else>
            <svg viewBox="0 0 32 32">
                <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="#fff">{{initial}}</text>
            </svg>
        </label>
    </div>
</template>

<script>
import Avataaars from "vuejs-avataaars";

export default {
    name: "avatar",
    components: {
        Avataaars
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        initialClass: {
            default: ""
        }
    },
    computed: {
        initial() {
            let first_name = this.user?.first_name || "";
            let last_name = this.user?.last_name || "";

            return `${first_name.charAt(0)}${last_name.charAt(0)}`;
        }
    }
};
</script>

<style lang="scss" scoped>
.avatar {
    aspect-ratio: 1;

    .initial {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 3% !important;

        svg {
            width: 100%;
            fill: #fff;
        }
    }

    ::v-deep {
        svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
</style>